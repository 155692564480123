import { IconChevronRight } from '@troon/icons';
import { DialogTrigger, Dialog, Calendar, useFocusable } from '@troon/ui';
import dayjs from 'dayjs';
import { For, mergeProps } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import { formatDateTimeAttribute } from '../modules/date-formatting';
import type { JSX } from 'solid-js';
import type { Dayjs } from 'dayjs';

type Props = {
	minDate: Dayjs;
	maxDate?: Dayjs;
	selectedDay: Dayjs;
	setSelectedDay: (day: Dayjs) => void;
};

export function DatePicker(props: Props) {
	return (
		<div class="relative w-full rounded border border-neutral-500">
			<ul class="flex snap-x flex-row overflow-x-auto pe-8">
				<For each={new Array(Math.min(10, props.maxDate?.diff(props.minDate, 'days') ?? 10)).fill(0).map((a, i) => i)}>
					{(v, index) => {
						const day = props.minDate.add(index(), 'day');
						const date = day.toDate();
						const weekday = day.format('ddd');
						const dayNum = day.format('D');
						return (
							<li class="min-w-16 grow snap-start">
								<button
									onClick={() => {
										props.setSelectedDay(day);
									}}
									class={twMerge(
										// hacky: disable hover states on < small screens
										// prevents mobile browsers from keeping :hover after clicked
										'block w-full border-r border-r-neutral-300 outline-none transition-colors duration-200 focus-visible:ring-4 focus-visible:ring-brand-100 active:bg-white sm:hover:bg-neutral-100 sm:hover:text-black',
										props.selectedDay.isSame(day, 'day') && 'bg-brand text-white',
									)}
								>
									<time
										datetime={formatDateTimeAttribute(date, false)}
										class="flex flex-col items-center justify-center gap-0 py-4"
									>
										<span class="text-sm">{weekday}</span> <span class="font-semibold">{dayNum}</span>
									</time>
								</button>
							</li>
						);
					}}
				</For>
				<li class="absolute inset-y-0 right-0">
					<DialogTrigger>
						<NextButton>
							<IconChevronRight />
							<span class="sr-only">More dates</span>
						</NextButton>
						<Dialog header="Select a date" headerLevel="h3" key="date-picker-calendar">
							{(handleClose) => (
								<div class="flex flex-row justify-center">
									<Calendar
										label="Tee times {date}"
										dayLabel="Show tee times for {date}"
										minDate={props.minDate}
										maxDate={props.maxDate}
										selectedDate={props.selectedDay}
										onSelect={(date) => {
											props.setSelectedDay(dayjs(date));
											handleClose();
										}}
									/>
								</div>
							)}
						</Dialog>
					</DialogTrigger>
				</li>
			</ul>
		</div>
	);
}

function NextButton(inputProps: JSX.HTMLAttributes<HTMLButtonElement>) {
	const focusProps = useFocusable();
	const props = mergeProps(inputProps, focusProps);

	return (
		<button
			{...props}
			class="h-full bg-neutral-500 px-2 transition-colors duration-200 hover:bg-brand-200 active:bg-brand active:text-white"
		/>
	);
}
