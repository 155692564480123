import { For, Match, createSignal, Switch, Show } from 'solid-js';
import { Button, Dialog, DialogTrigger, Picture, SquareLogo, Form } from '@troon/ui';
import { IconChevronLeft, IconChevronRight } from '@troon/icons';
import { gql, mutationAction, useMutation } from '../../../../../../graphql';
import { WelcomeBanner } from '../../../../../../components/welcome-banner';
import { imageUrl } from '../../../../../../modules/images';
import type { FacilityHeaderFragment } from '../../../../../../graphql';

export function PageWelcomeBanner(props: { facility: FacilityHeaderFragment; handleDismiss: () => void }) {
	const optOutAction = useMutation(optOut);

	return (
		<WelcomeBanner onDismiss={props.handleDismiss}>
			<p class="shrink text-sm">
				Welcome to our new and improved booking experience in partnership with Troon!
				<DialogTrigger>
					<Button appearance="transparent-current" class="inline-flex flex-nowrap text-nowrap px-2 py-1 normal-case">
						Learn more <IconChevronRight />
					</Button>
					<Dialog header="Welcome" headerLevel="h2" key="tee-time-welcome-banner">
						{(close) => {
							const [step, setStep] = createSignal(0);
							const content = welcomeContent({ facility: props.facility });
							return (
								<div class="flex flex-col gap-4">
									<div class="relative flex aspect-video items-center justify-center">
										<Picture
											src={props.facility.metadata?.hero?.url}
											class="absolute inset-0 aspect-video w-full rounded-md"
											sizes={[
												[800, 500],
												[400, 250],
											]}
										/>
										<Switch>
											<Match when={!content[step()]!.image}>
												<SquareLogo class="relative z-10 size-24 text-white" />
											</Match>
											<Match when>
												<Picture
													src={content[step()]!.image}
													class="absolute inset-x-0 bottom-0 z-10 mx-auto w-3/4"
													sizes={[
														[588, 536],
														[294, 268],
													]}
												/>
											</Match>
										</Switch>
									</div>
									<h3 class="text-center text-2xl font-semibold">{content[step()]!.title}</h3>
									<p class="text-center">{content[step()]!.content}</p>

									<ul aria-label="Welcome screens" class="flex flex-row justify-center gap-2">
										<For each={content}>
											{(item, index) => (
												<li
													aria-current={index() === step()}
													class="text-neutral-200 transition-colors duration-200 aria-current:text-brand"
												>
													<button class="group size-8" onClick={() => setStep(index())}>
														<span class="block h-1 rounded bg-current group-hover:bg-brand-300" />
														<span class="sr-only">{content[index()]!.title}</span>
													</button>
												</li>
											)}
										</For>
									</ul>

									<div class="flex flex-col gap-2">
										<div class="flex flex-row flex-wrap gap-2">
											<Show when={step() !== 0}>
												<Button appearance="secondary" class="shrink grow-0" onClick={() => setStep((s) => s - 1)}>
													<IconChevronLeft /> Back
												</Button>
											</Show>
											<Switch>
												<Match when={step() !== content.length - 1}>
													<Button appearance="primary" type="button" class="grow" onClick={() => setStep((s) => s + 1)}>
														Continue <IconChevronRight />
													</Button>
												</Match>
												<Match when>
													<Button appearance="primary" class="grow" onClick={() => close()}>
														Find a tee time
													</Button>
												</Match>
											</Switch>
										</div>
										<Show when={step() === 0}>
											<Form action={optOutAction} document={optOutMutation}>
												<input type="hidden" name="slug" value={props.facility.slug} />
												<Button appearance="transparent" type="submit">
													Book with old booking experience
												</Button>
											</Form>
										</Show>
									</div>
								</div>
							);
						}}
					</Dialog>
				</DialogTrigger>
			</p>
		</WelcomeBanner>
	);
}
const welcomeContent = (props: {
	facility: FacilityHeaderFragment;
}): Array<{ title: string; content: string; image?: string }> => [
	{
		title: `Welcome, ${props.facility.name} Golfers!`,
		content: 'We’ve partnered with Troon to bring you a new and improved booking experience for tee times.',
	},
	{
		image: imageUrl('/photos/tee-time.png'),
		title: 'Book & manage tee times',
		content:
			'Seamlessly find and book tee times that match your playing preferences. Filter by date, time and number of players.',
	},
	{
		image: imageUrl('/photos/rewards-20240722.png'),
		title: 'Earn Troon Rewards Points',
		content:
			'Earn Troon Rewards points when you pay for your rounds. Unlock discounted rates and complimentary rounds of golf.',
	},
];

export const optOutMutation = gql(`
	mutation optOutFacilityRedirect($slug: String!) {
		persistFacilityRedirect(facilitySlug: $slug, variation: "control") {
			redirectUrl
		}
	}`);

export const optOut = mutationAction(optOutMutation, {
	onSuccess: async (res) => {
		if (res) {
			window.location.href = res.persistFacilityRedirect.redirectUrl;
		}
	},
});
