import { For, Show } from 'solid-js';
import { Label, RadioGroup, RadioBar, RadioBarButton, Field, Select, Option } from '@troon/ui';
import { timeframes } from '../../../../../../modules/date-formatting';
import type { Facility } from '../../../../../../graphql';

export type Store = {
	includeCart: boolean;
	players: number | undefined;
	courseIds: Array<string>;
	startAt: number;
	endAt: number;
	date: string;
};

type FilterProps = {
	facility: Facility;
	onSelectPlayers: (players: number | undefined) => void;
	onSelectCourse: (courseId: string) => void;
	onSelectTime: (frame: [number, number]) => void;
	onSwitchIncludesCart: (include: boolean) => void;
} & Store;

export function TeeTimeFilters(props: FilterProps) {
	return (
		<>
			<Show when={(props.facility.courses.length ?? 0) > 1}>
				<Field name="course">
					<Label>Course</Label>
					<Select
						onChange={(e) => {
							props.onSelectCourse(e.currentTarget.value);
						}}
					>
						<Option value="__all__">All courses</Option>
						<optgroup label="Courses">
							<For each={props.facility.courses}>
								{(course) => (
									<Option value={course.id} selected={props.courseIds.length === 1 && props.courseIds[0] === course.id}>
										{course.name}
									</Option>
								)}
							</For>
						</optgroup>
					</Select>
				</Field>
			</Show>

			<Field name="time">
				<Label>Time</Label>
				<Select
					onChange={(e) => {
						props.onSelectTime(timeframes[e.currentTarget.value]!);
					}}
				>
					<For each={Object.entries(timeframes)}>
						{([label, [start, end]]) => (
							<Option selected={start === props.startAt && end === props.endAt}>{label}</Option>
						)}
					</For>
				</Select>
			</Field>

			<RadioGroup name="radios" onSelect={(value) => props.onSelectPlayers(parseInt(value, 10))}>
				<Label>Players</Label>
				<RadioBar>
					<RadioBarButton checked={!props.players}>
						<Label>Any</Label>
					</RadioBarButton>
					<RadioBarButton value="1" checked={props.players === 1}>
						<Label>1</Label>
					</RadioBarButton>
					<RadioBarButton value="2" checked={props.players === 2}>
						<Label>2</Label>
					</RadioBarButton>
					<RadioBarButton value="3" checked={props.players === 3}>
						<Label>3</Label>
					</RadioBarButton>
					<RadioBarButton value="4" checked={props.players === 4}>
						<Label>4</Label>
					</RadioBarButton>
				</RadioBar>
			</RadioGroup>
		</>
	);
}
