import { For, Match, Suspense, createSignal, useContext, Switch, Show, createEffect, createMemo } from 'solid-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { ActivityIndicator, Button, Calendar, Dialog, DialogTrigger } from '@troon/ui';
import { Meta, Title } from '@solidjs/meta';
import { IconChevronLeft, IconChevronRight, IconFilter, IconUnfoldMore } from '@troon/icons';
import { useTrackEvent } from '@troon/analytics';
import { createAsync, useSearchParams } from '@solidjs/router';
import { createStore } from 'solid-js/store';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { gql } from '../../../../../graphql';
import { FacilityCtx } from '../../../../../providers/facility';
import { FacilityHeader } from '../../../../../components/facility/header';
import { Grid, GridMain, GridSidebar } from '../../../../../components/layouts/grid';
import { DatePicker } from '../../../../../components/date-picker';
import { getSchemaString } from '../../../../../modules/schema/schema';
import { breadcrumbs } from '../../../../../modules/schema/breadcrumb-list';
import { image } from '../../../../../modules/schema/image';
import { webpage } from '../../../../../modules/schema/webpage';
import { GenericLayout } from '../../../../../layouts/generic';
import { TeeTime } from '../../../../../components/tee-time';
import { cachedQuery } from '../../../../../graphql/cached-get';
import { dayTimeToDate } from '../../../../../modules/date-formatting';
import { useUtmParams } from '../../../../../providers/utm';
import { TeeTimeAlertPrompt } from './components/tee-time-alert';
import { TeeTimeFilters } from './components/tee-time-filters';
import { PageWelcomeBanner } from './components/welcome-banner';
import type { Params } from '@solidjs/router';
import type { Store } from './components/tee-time-filters';
import type { DayFragment, Facility, CourseTeeTime, TeeTimeFragment } from '../../../../../graphql';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(tz);

function queryParamStore<T extends Record<string, unknown>>(params: Partial<Params>, store: T): T {
	const out = { ...store };
	for (const [key, val] of Object.entries(out)) {
		if (!(key === 'players' || key === 'lat' || key === 'lon') && typeof params[key] === 'undefined') {
			continue;
		}
		const pVal = params[key] as string;
		if (typeof pVal === 'undefined') {
			continue;
		}
		if (Array.isArray(val)) {
			// @ts-expect-error TS is weird about the generic here
			out[key] = pVal.split(',');
		} else if (typeof val === 'boolean') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = !!JSON.parse(pVal);
		} else if (key === 'players' || typeof val === 'number') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = parseInt(pVal, 10);
		} else if (key === 'lat' || key === 'lon' || typeof val === 'number') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = parseFloat(pVal);
		} else {
			// @ts-expect-error TS is weird about the generic here
			out[key] = pVal;
		}
	}
	return out;
}

export default function ReserveTeeTime() {
	const trackEvent = useTrackEvent();
	const facility = useContext(FacilityCtx);
	const utm = useUtmParams();

	const now = dayjs.utc().tz(facility()?.facility.timezone);
	const today = now.startOf('day');
	const initialDay = now.hour() < 16 ? now : now.add(1, 'day');

	const [params, setParams] = useSearchParams();

	const [filters, setFilters] = createStore<Store>(
		queryParamStore<Store>(params, {
			includeCart: false,
			players: undefined,
			courseIds: facility()?.facility.courses.map((c) => c.id) ?? [],
			startAt: 0,
			endAt: 24,
			date: initialDay.format('YYYY-MM-DD'),
		}),
	);
	const [showBanner, setShowBanner] = createSignal(!!utm().source);

	createEffect(() => {
		setParams(
			{
				...filters,
				courseIds: filters.courseIds.join(','),
			},
			{ replace: true },
		);
	});

	createEffect(() => {
		if (!facility()?.facility.id) {
			return;
		}
		const date = dayjs(filters.date);

		trackEvent('changeTeeTimeDay', {
			courseId: facility()?.facility.id,
			year: date.year(),
			month: date.month() + 1,
			day: date.date(),
			hour: 0,
			minute: 0,
		});
	});

	const maxDate = createMemo(() => {
		return facility()?.facility.courses.reduce((max, course) => {
			const day = course.bookingWindowDay as DayFragment;
			const date = dayjs.tz(`${day.year}-${day.month}-${day.day}`, facility()?.facility.timezone).endOf('day');
			return max.isBefore(date) ? date : max;
		}, today);
	});

	const selectedDay = createMemo(() => dayjs.tz(filters.date, facility()?.facility.timezone).startOf('day'));

	const filterCount = createMemo(() => {
		return (
			(filters.players ? 1 : 0) +
			(filters.includeCart ? 1 : 0) +
			(filters.startAt > 0 || filters.endAt < 24 ? 1 : 0) +
			(filters.courseIds.length !== (facility()?.facility.courses.length ?? 0) ? 1 : 0)
		);
	});

	const teeTimes = createAsync(
		async () => {
			const { date, ...data } = filters;
			const day = dayjs(date);
			const params = {
				...data,
				year: day.year(),
				month: day.month() + 1,
				day: day.date(),
			};
			trackEvent('getTeeTimesRequest', params);
			const res = await getTeeTimes(params);
			if (res) {
				trackEvent('getTeeTimesSuccess', { ...params, resultsCount: res?.teeTimes.length });
			} else {
				trackEvent('getTeeTimesFailure', params);
			}
			return res;
		},
		{ deferStream: false },
	);

	return (
		<GenericLayout>
			<Show when={facility()?.facility}>
				{(facility) => (
					<>
						<Title>{`${facility()?.name} | Book tee times | Troon`}</Title>
						<Meta
							name="description"
							content={`Reserve golf tee times at ${facility()?.name} and earn Troon Rewards points.`}
						/>
						<script
							type="application/ld+json"
							innerText={getSchemaString([
								breadcrumbs(`/course/${facility().slug}/reserve-tee-time`, [
									{ name: 'Home', pathname: '/' },
									{ name: 'Courses', pathname: '/courses' },
									{ name: facility().name ?? '', pathname: `/course/${facility().slug}` },
									{ name: 'Reserve tee times', pathname: `/course/${facility().slug}/reserve-tee-time` },
								]),
								image(facility().metadata!.hero!.url ?? ''),
								webpage(`/course/${facility().slug}`, {
									title: `${facility.name} | Reserve tee times | Troon Rewards | Book tee times`,
								}),
							])}
						/>
						<Show when={showBanner()}>
							<div class="relative -top-8 md:-top-16 md:-mb-8">
								<PageWelcomeBanner facility={facility()} handleDismiss={() => setShowBanner(false)} />
							</div>
						</Show>
					</>
				)}
			</Show>

			<div class="mx-auto w-full px-4 md:container sm:px-6 md:px-12 xl:px-24">
				<FacilityHeader facility={facility()?.facility ?? {}} showHero />

				<div class="mb-8 hidden w-full md:block">
					<DatePicker
						minDate={today}
						maxDate={maxDate()}
						selectedDay={selectedDay()}
						setSelectedDay={(day) => {
							setFilters({
								date: day.format('YYYY-MM-DD'),
							});
						}}
					/>
				</div>

				<Suspense>
					<Grid>
						<GridMain class="order-2">
							<div
								// eslint-disable-next-line tailwindcss/no-arbitrary-value
								class="sticky inset-x-0 top-[calc(4rem-1px)] z-20 -mx-4 flex grow flex-row flex-nowrap items-center justify-between gap-2 border-y border-y-neutral bg-white p-2 px-4 md:static md:mx-0 md:w-full md:border-none md:px-0 md:pb-4"
							>
								<DialogTrigger>
									<div class="order-2 md:text-2xl">
										<Button appearance="transparent" class="flex-nowrap px-2 py-1">
											<h2
												class="text-nowrap font-semibold normal-case text-black"
												data-facility-date={today.format('YYYY-MM-DD')}
											>
												{selectedDay().format('dddd, MMMM D')}
											</h2>
											<IconUnfoldMore />
											<span class="sr-only">Select date</span>
										</Button>
									</div>
									<Dialog header="Select a date" headerLevel="h3" key="tee-time-calendar">
										{(handleClose) => (
											<div class="flex flex-row justify-center">
												<Calendar
													label="Tee times {date}"
													dayLabel="Show tee times for {date}"
													minDate={today}
													maxDate={maxDate()}
													selectedDate={selectedDay()}
													onSelect={(date) => {
														const day = dayjs(date);
														setFilters({
															date: day.format('YYYY-MM-DD'),
														});
														handleClose();
													}}
												/>
											</div>
										)}
									</Dialog>
								</DialogTrigger>
								<Button
									appearance="transparent"
									class="order-1 shrink grow-0 px-2 py-1 md:hidden"
									disabled={selectedDay()?.isSameOrBefore(today, 'day') ?? true}
									onClick={() => {
										const prev = selectedDay().subtract(1, 'day');
										setFilters({
											date: prev.format('YYYY-MM-DD'),
										});
									}}
								>
									<IconChevronLeft />
									<span class="sr-only">Previous day</span>
								</Button>
								<Button
									appearance="transparent"
									class="order-3 shrink grow-0 px-2 py-1 md:hidden"
									disabled={selectedDay()?.isSameOrAfter(maxDate(), 'day') ?? true}
									onClick={() => {
										const next = selectedDay().add(1, 'day');
										setFilters({
											date: next.format('YYYY-MM-DD'),
										});
									}}
								>
									<IconChevronRight />
									<span class="sr-only">Next day</span>
								</Button>
								<Suspense fallback={null}>
									<p class="order-4 hidden lg:block">{teeTimes()?.teeTimes.length ?? 0} tee times</p>
								</Suspense>
							</div>

							<div class="container mx-auto flex flex-row items-center justify-between py-4 ps-2 sm:max-w-none sm:shrink lg:hidden">
								<Suspense fallback={null}>
									<p>{teeTimes()?.teeTimes.length ?? 0} tee times</p>
								</Suspense>
								<DialogTrigger>
									<Button appearance="transparent" class="ms-auto shrink grow-0">
										Filter{' '}
										<Switch>
											<Match when={filterCount()}>
												<span class="inline-flex size-6 items-center justify-center rounded-full bg-brand text-white">
													{filterCount()}
												</span>
											</Match>
											<Match when>
												<IconFilter />
											</Match>
										</Switch>
									</Button>
									<Dialog header="Filter tee times" headerLevel="h2" key="tee-time-filter">
										{(handleClose) => (
											<div class="flex flex-col gap-6">
												<Show when={facility()?.facility}>
													{(facility) => (
														<TeeTimeFilters
															facility={facility() as Facility}
															onSelectPlayers={(count) => setFilters('players', count)}
															onSelectCourse={(courseId) => {
																if (courseId === '__all__') {
																	setFilters('courseIds', facility().courses.map((c) => c.id) ?? []);
																	return;
																}
																setFilters('courseIds', [courseId]);
															}}
															onSelectTime={(timeframe) => setFilters({ startAt: timeframe[0], endAt: timeframe[1] })}
															onSwitchIncludesCart={(include) => setFilters('includeCart', include)}
															{...filters}
														/>
													)}
												</Show>
												<Button type="button" onClick={handleClose}>
													View tee times
												</Button>
											</div>
										)}
									</Dialog>
								</DialogTrigger>
							</div>
							<div class="rounded-lg border border-neutral-500">
								<Suspense
									fallback={
										<div class="p-8 text-neutral-700">
											<ActivityIndicator>Loading tee times…</ActivityIndicator>
										</div>
									}
								>
									<ul class="flex h-full flex-col">
										<For
											each={teeTimes()?.teeTimes}
											fallback={
												<>
													<li class="flex basis-32 items-center justify-center border-b border-neutral-500">
														<p class="px-8 text-center text-lg">
															No available tee times for {selectedDay().format('dddd, MMMM D')}. Please select another
															day.
														</p>
													</li>
													<li class="p-6">
														<TeeTimeAlertPrompt />
													</li>
												</>
											}
										>
											{(teeTime, index) => {
												return (
													<>
														<li class="border-b border-neutral-500">
															<TeeTime
																{...(teeTime as TeeTimeFragment)}
																showCourse={(facility()?.facility.courses.length ?? 0) > 1}
															/>
														</li>
														<Show
															when={index() === largestGapIndex((teeTimes()?.teeTimes as Array<CourseTeeTime>) ?? [])}
														>
															<li class="border-b border-neutral-500 p-6">
																<TeeTimeAlertPrompt
																	selectedDay={dayjs(filters.date).toDate()}
																	selectedPlayers={filters.players}
																	selectedTime={[filters.startAt, filters.endAt]}
																/>
															</li>
														</Show>
													</>
												);
											}}
										</For>
									</ul>
								</Suspense>
							</div>
						</GridMain>

						<GridSidebar class="order-1 row-start-2 hidden lg:block">
							<div class="sticky top-24 flex flex-col gap-6 rounded-lg border border-neutral-500 p-6">
								<h3 class="text-lg font-semibold">Filter results</h3>
								<Show when={facility()?.facility}>
									{(facility) => (
										<TeeTimeFilters
											facility={facility() as Facility}
											onSelectPlayers={(count) => setFilters('players', count)}
											onSelectCourse={(courseId) => {
												if (courseId === '__all__') {
													setFilters('courseIds', facility().courses.map((c) => c.id) ?? []);
													return;
												}
												setFilters('courseIds', [courseId]);
											}}
											onSelectTime={(timeframe) => setFilters({ startAt: timeframe[0], endAt: timeframe[1] })}
											onSwitchIncludesCart={(include) => setFilters('includeCart', include)}
											{...filters}
										/>
									)}
								</Show>
							</div>
						</GridSidebar>
					</Grid>
				</Suspense>
			</div>
		</GenericLayout>
	);
}

function largestGapIndex(teeTimes: Array<CourseTeeTime>) {
	let largestMs = 1000 * 60 * 45; // 45 minutes in ms
	let i = 0;
	for (; i < teeTimes.length; i++) {
		const teeTime = teeTimes[i]!;
		const nextTeeTime = teeTimes[i + 1];
		if (!nextTeeTime) {
			return i;
		}

		const diffMs = dayTimeToDate(nextTeeTime.dayTime).valueOf() - dayTimeToDate(teeTime.dayTime).valueOf();
		if (diffMs >= largestMs) {
			return i;
		}
		largestMs = Math.max(largestMs, diffMs);
	}

	return i;
}

const teeTimesQuery = gql(`
query teeTimes(
	$courseIds: [String!]!,
	$year: Int!,
	$month: Int!,
	$day: Int!,
	$startAt: Int!,
	$endAt: Int!,
	$includeCart: Boolean,
	$players: Int
) {
	teeTimes: courseTeeTimes(
		courseIds: $courseIds,
		day: { year: $year, month: $month, day: $day },
		filters: {
			startAt: { hour: $startAt, minute: 0 },
			endAt: { hour: $endAt, minute: 0 },
			players: $players,
			includeCart: $includeCart,
		}
	) {
		...TeeTime
	}
}`);

const getTeeTimes = cachedQuery(teeTimesQuery);
